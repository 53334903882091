//  -yzm

//other
let __system = {
  __activeid: "",
  __menu: [
  {
    pageFlag: false,
    id: "syc",
    title: "报表",
    icon: "el-icon-menu",
    children: [
      {
        pageFlag: false,
        id: "sysrpt",
        title: "销售报表",
        icon: "el-icon-menu",
        children: [
        {
          pageFlag: true,
          id: "rpt1",
          title: "单品单客户月数据对比",
          name: "rpt1",
          icon: "el-icon-document",
          component: "views/dlh/Rpt1.vue",
          visible:true
        },
        {
          pageFlag: true,
          id: "rpt2",
          title: "单品月数据统计对比",
          name: "rpt2",
          icon: "el-icon-document",
          component: "views/dlh/Rpt2.vue",
          visible:true
        },
        {
          pageFlag: true,
          id: "rpt3",
          title: "客户月数据统计对比",
          name: "rpt3",
          icon: "el-icon-document",
          component: "views/dlh/Rpt3.vue",
          visible:true
        },
        {
          pageFlag: true,
          id: "rpt4",
          title: "单品单客户数据对比",
          name: "rpt4",
          icon: "el-icon-document",
          component: "views/dlh/Rpt4.vue",
          visible:true
        },
        {
          pageFlag: true,
          id: "rpt5",
          title: "单品数据统计对比",
          name: "rpt5",
          icon: "el-icon-document",
          component: "views/dlh/Rpt5.vue",
          visible:true
        },
        {
          pageFlag: true,
          id: "rpt6",
          title: "客户数据统计对比",
          name: "rpt6",
          icon: "el-icon-document",
          component: "views/dlh/Rpt6.vue",
          visible:true
        }
      
      ]}
      
],
  }, ],
  __mdilist: [],
  set activeid(val) {
    this.__activeid = val;
  },
  get activeid() {
    return this.__activeid;
  },
  set menu(val) {
    this.__menu = val;
  },
  get menu() {
    return this.__menu;
  },
  set mdilist(val) {
    this.__mdilist = val;
  },
  get mdilist() {
    return this.__mdilist;
  }
}


let ld = (function (__system) {
  let _ld = {
    "system": __system,
  }
  return _ld;
})(__system)
export default ld